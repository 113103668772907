import { getSessionRuns } from '../../../../stores/configs/getters';
import ViewResults from '../IconButtons/ViewResults';
import { ReactElement } from 'react';
import { MoreButtonProps } from '../../../../components/Buttons/MoreButton';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useConfigs } from '../../../../stores/configs/useConfigs';
import { useRuns, useSessionRuns } from '../../../../stores/runs/useRuns';
import { updateRuns } from '../../../../stores/ajax/updateRuns';
import { IconButton, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { TRun } from '../../../../common/types/run';
import { RunStatus } from '../../../../common/types/enums';
import ListEditButton from '../ListEditButton';
import { setDataLoading, setEditedRuns, setLastModifiedRun } from '../../../../stores/runs/setters';
import setUrlSearchParams from '../../../../utils/setUrlSearchParams';
import { TConfig } from '../../../../common/types/config';
import { StreamlinesBroom } from '../../../../icons/StreamlinesBroom';
import FixRunOrder from '../IconButtons/FixRunOrder';
import { updateSession } from '../../../../stores/ajax/updateSession';
import { AutoCalibration } from '../IconButtons/AutoCalibration';
import { updateCalibration } from '../../../../stores/ajax/updateCalibration';
import { useCalibrations } from '../../../../stores/calibrations/useCalibrations';
import EditButton from '../../../../components/Buttons/EditButton';
import { StreamlinesPlotter } from '../../../../icons/StreamlinesPlotter';
import { hasSuccessfulRuns, runFromId, runsFromMap } from '../../../../utils/state/runs';
import { subMenu } from './subMenu';
import { Dashboard as DashboardIcon, Refresh } from '@mui/icons-material';
import LockSession from '../IconButtons/LockSession';
import { useSessions } from '../../../../stores/sessions/useSessions';
import { useSWRGet } from '../../../../stores/ajax/getData';
import moment from 'moment/moment';
import { sortSessionConfigs } from '../../../../utils/state/configs';
import { updateGroupRuns } from '../../../../stores/ajax/updateGroupRuns';
import { setLastModifiedConfig } from '../../../../stores/configs/setters';

export function useExtraButtonsSessions(): (id: string | number) => MoreButtonProps['MenuItems'] {
  const navigate = useNavigate();
  const [{ byId }, , refreshSessions] = useSessions();
  const [{ configsByTestSessionId }, , refreshConfigs] = useConfigs();
  const [{ runsById }, runsDispatch, refreshRuns] = useRuns();
  const [, , refreshCalibrations] = useCalibrations();
  const runStatusGood = (id: string | number): boolean =>
    id
      ? !!configsByTestSessionId[id]?.find((config) =>
          hasSuccessfulRuns(runsFromMap(config.run_ids ?? [], runFromId(runsById))),
        )
      : false;

  const lockAction = (id: string | number) => async (): Promise<void> => {
    await updateSession({
      sessionId: id as string,
      status: byId[id].status === 2000 ? 1000 : 2000,
    });
    refreshSessions();
    refreshConfigs();
    refreshRuns();
  };

  return (id) => [
    {
      element: (
        <>
          <EditButton data-testid={'edit-session-configs-btn'} color={'success'} label={'Edit Session Configs'} />
          {'Edit Details'}
        </>
      ),
      disabled: byId[id].status === 2000,
      onClick: (): void => {
        if (id) {
          setEditedRuns(getSessionRuns(id, configsByTestSessionId), runsDispatch);
          navigate(`/session/edit-configs/${id}`);
        }
      },
    },
    {
      element: <ViewResults />,
      disabled: !runStatusGood(id),
      color: 'success',
      onClick: (): void => (id ? navigate(`/view-results?sessionId=${id}`) : undefined),
    },
    {
      element: (
        <>
          <IconButton color={'success'}>
            <StreamlinesPlotter />
          </IconButton>
          Plotter
        </>
      ),
      color: 'success',
      disabled: !runStatusGood(id),
      onClick: (): void => {
        if (id) {
          setEditedRuns(getSessionRuns(id, configsByTestSessionId), runsDispatch);
          navigate(`/session/view/plotter?sessionUuid=${id}`);
        }
      },
    },
    {
      element: <AutoCalibration data-testid={'autocalibrate-btn'} />,
      color: 'success',
      disabled: byId[id].status === 2000,
      onClick: async (): Promise<void> => {
        await updateCalibration({
          verb: 'POST',
          autocalibrate: true,
          testsession_id: id,
        });

        refreshRuns();
        refreshCalibrations();
      },
    },
    {
      element: <FixRunOrder />,
      color: 'success',
      disabled: byId[id].status === 2000,
      onClick: async (): Promise<void> => {
        await updateSession({
          sessionId: id as string,
          autonumber: 1,
          autonumber_overwrite: true,
        });
        refreshRuns();
      },
    },
    {
      element: <LockSession locked={byId[id].status === 2000} />,
      warn: byId[id].status !== 2000 ? 'All planned runs will be lost when locking the session. Continue?' : null,
      color: 'success',
      onClick: lockAction(id),
    },
  ];
}

export function useExtraButtonsConfig(): (config: TConfig) => MoreButtonProps['MenuItems'] {
  const { sessionUuid = '' } = useParams();
  const SessionRuns = useSessionRuns(sessionUuid);
  const [{ byId }] = useSessions();

  const { findConfigRuns } = SessionRuns;

  return (config) => [
    {
      element: (
        <>
          <IconButton color={'success'}>
            <StreamlinesBroom size={'md'} />
          </IconButton>
          Clean Configs
        </>
      ),
      color: 'success',
      disabled: byId[sessionUuid].status === 2000,
      onClick: async () => {
        if (config) {
          await updateRuns({
            runs: findConfigRuns(config.id).map((run, _index, array) => {
              run.description = config.description;
              run.calibration = array[0].calibration;
              return run;
            }),
            verb: 'PUT',
          });
        }
      },
    },
    {
      element: <Typography color={'success'}>Calibrations</Typography>,
      color: 'success',
      subMenu: subMenu(config),
    },
  ];
}

export function useExtraButtonsRuns(): (run: TRun) => MoreButtonProps['MenuItems'] {
  const {
    data: unprocessedRuns,
    error,
    isLoading,
    mutate,
  } = useSWRGet<any[]>({
    dataType: 'runs',
    scope: 'need_processing',
    dates: `${moment().subtract(1000, 'd').format('yyyy-MM-DD')},${moment().format('yyyy-MM-DD')}`,
  });
  const { sessionUuid } = useParams();
  const { futureRuns, swapRunNumber } = useSessionRuns(sessionUuid);
  const futureRunNumbers = futureRuns.map(({ run_number }) => `${run_number}`);

  const navigate = useNavigate();
  const pathParent = 'runs';
  const [configUuid = ''] = useOutletContext<string[]>();
  const [{ dataLoading }, runsDispatch, refreshRuns] = useRuns();
  const [{ configsByTestSessionId }, configsDispatch, refreshConfigs] = useConfigs();

  return (run) => [
    {
      element: (
        <ListEditButton data-testid={'edit-run-btn'} color={'success'} disabled={false} item={run} name={'Run'} />
      ),
      disabled: false,
      color: 'success',
      onClick: (): void => {
        if (run.id) {
          setEditedRuns([run.id], runsDispatch);
          navigate(`/${pathParent}/edit/${run.id}${setUrlSearchParams({ configUuid })}`);
        }
      },
    },
    {
      element: (
        <>
          <IconButton color={'success'}>
            <StreamlinesPlotter />
          </IconButton>
          Plotter
        </>
      ),
      color: 'success',
      disabled: (run.run_status ?? 0) < RunStatus.PROCESSED_ERROR,
      onClick: (): void => {
        if (run.id) {
          setEditedRuns([run.id], runsDispatch);
          navigate('/session/view/plotter');
        }
      },
    },
    {
      element: (
        <>
          <EditButton icon={<DashboardIcon />} color={'success'} />
          Details
        </>
      ),
      color: 'success',
      disabled: (run.run_status ?? 0) < RunStatus.PROCESSED_ERROR,
      onClick: (): void => {
        if (run.id) {
          setEditedRuns([run.id], runsDispatch);
          navigate('/runs/view/dashboard');
        }
      },
    },
    {
      element: sessionUuid ? (
        <Autocomplete
          sx={{ width: '200px' }}
          value={configsByTestSessionId[sessionUuid].find(({ id }) => id === run.groups_runs?.[0].group_id)}
          options={configsByTestSessionId[sessionUuid].sort(sortSessionConfigs)}
          getOptionLabel={(option): string => `${option.letter}: ${option.description}`}
          onChange={async (_event, newValue): Promise<void> => {
            if (newValue && run.groups_runs?.[0]) {
              run.description = newValue.description;
              await Promise.all([
                updateRuns({
                  runs: [run],
                  startNumber: 1,
                  runTimeGap: 5,
                }),
                updateGroupRuns({
                  groupId: [newValue.id],
                  addRuns: [run.id],
                  removeGroupRuns: [run.groups_runs[0].id],
                }),
              ]);
              setLastModifiedConfig(configsDispatch)(newValue.id);
              setLastModifiedRun(run.id, runsDispatch);
            }
            refreshRuns();
            refreshConfigs();
          }}
          renderInput={(props): ReactElement => {
            return (
              <TextField
                {...props}
                label={'Change config'}
                InputProps={{
                  ...props.InputProps,
                  endAdornment: <>{props.InputProps.endAdornment}</>,
                }}
              />
            );
          }}
        />
      ) : null,
      color: 'success',
      onClick: (): void => undefined,
    },
    {
      element: (
        <Autocomplete
          sx={{ width: '200px' }}
          value={futureRunNumbers.includes(`${run.run_number}`) ? `${run.run_number}` : null}
          options={futureRuns.map(({ run_number }) => `${run_number}`)}
          onChange={async (_event, newValue): Promise<void> => {
            if (newValue) {
              await updateRuns({
                runs: swapRunNumber(+newValue, run),
                startNumber: 1,
                runTimeGap: 5,
              });
              refreshRuns();
            }
          }}
          renderInput={(props): ReactElement => {
            return (
              <TextField
                {...props}
                label={'Set run number'}
                InputProps={{
                  ...props.InputProps,
                  endAdornment: <>{props.InputProps.endAdornment}</>,
                }}
              />
            );
          }}
        />
      ),
      disabled: run.run_status !== RunStatus.CREATED,
      color: 'success',
      onClick: (): void => undefined,
    },
    {
      disabled: run.run_status !== RunStatus.CREATED || error || isLoading,
      color: 'success',
      onClick: (): void => undefined,
      element: (
        <>
          <Autocomplete
            sx={{ width: '200px' }}
            value={null}
            disabled={dataLoading.has(run.id)}
            options={unprocessedRuns ?? []}
            getOptionLabel={(option): string => `${option.date} ${option.time}`}
            onChange={async (_event, newValue): Promise<void> => {
              setDataLoading(run.id, runsDispatch);
              await updateRuns({
                id: run.id,
                runs: [{ id: run.id, ...newValue, session_id: sessionUuid }],
              });
              await mutate();
              await refreshRuns();
              setDataLoading(run.id, runsDispatch);
            }}
            renderInput={(props): ReactElement => {
              return (
                <TextField
                  {...props}
                  label={'Set missing run data'}
                  InputProps={{
                    ...props.InputProps,
                    endAdornment: <>{props.InputProps.endAdornment}</>,
                  }}
                />
              );
            }}
          />
          <IconButton onClick={() => mutate()}>
            <Refresh />
          </IconButton>
        </>
      ),
    },
  ];
}
